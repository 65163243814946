import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from "react";

import PanelBg from "../../assets/panel-bg.png";
import { api, socket } from "../../services/api";

import Footer from "./components/Footer";
import Header from "./components/Header";

import { usePanel } from "../../contexts/PanelContext";
import {
  ICallPanel,
  IServicePassword,
  IServicePasswordPanel,
} from "../../models";
import CallModal from "./components/CallModal";

const TIME_LIMIT = 7;
const TIME_INITIAL = 1;

function NewPanel() {
  const theme = useTheme();
  const { panel, isOpen } = usePanel();
  const [servicePasswords, setServicePasswords] = useState<IServicePassword[]>(
    []
  );
  const [currentServicePassword, setCurrentServicePassword] =
    useState<IServicePassword | null>(null);
  const [panelsList, setPanelsList] = useState<IServicePasswordPanel[]>([]);
  const [clock, setClock] = useState<number>(TIME_INITIAL);
  const [currentIndex, setCurrentIndex] = useState(0);

  const msg = useMemo(() => new SpeechSynthesisUtterance(), []);

  const gatAllPasswords = async (panel: ICallPanel) => {
    try {
      const res = await api.get(
        `/users-patients/passwordsbygroups?idpainelchamado=${panel?.idpainelchamado}`
      );
      setServicePasswords(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (currentServicePassword === null && panel !== null && isOpen) {
      gatAllPasswords(panel);
    }
  }, [currentServicePassword, panel, isOpen]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("[IO] => connected");
    });
    socket.on(`nextPasswordByServer`, (data: any) => {
      setPanelsList((old) => [...old, data]);
    });
  }, [panel]);

  useEffect(() => {
    let time: any = null;
    if (clock < TIME_LIMIT && currentServicePassword !== null) {
      time = setInterval(() => {
        setClock((state) => state + 1);
      }, 1000);
    } else {
      if (panelsList.length - 1 < currentIndex) {
        setCurrentIndex(0);
        setPanelsList([]);
      } else {
        setCurrentIndex((old) => old + 1);
      }
      setClock(TIME_INITIAL);
    }
    return () => clearInterval(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clock, currentServicePassword, panel]);

  useEffect(() => {
    if (
      panelsList.length > 0 &&
      currentIndex <= panelsList.length - 1 &&
      isOpen
    ) {
      const existsPanel = panelsList[currentIndex].panels.find(
        (item) => item.idpainel === panel?.idpainelchamado
      );

      if (existsPanel) {
        setCurrentServicePassword(panelsList[currentIndex].password);
      } else {
        setCurrentServicePassword(null);
      }
    } else {
      setCurrentServicePassword(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelsList, currentIndex, panel, isOpen]);

  useEffect(() => {
    if (currentServicePassword) {
      msg.text = "";
      msg.text = `Atenção! Senha ${currentServicePassword?.senha}!`;
      window.speechSynthesis.speak(msg);
    }
  }, [currentServicePassword, msg]);

  return (
    <>
      {currentServicePassword !== null && isOpen && (
        <CallModal
          currentServicePassword={currentServicePassword}
          setCurrentServicePassword={setCurrentServicePassword}
        />
      )}
      <Box
        sx={{
          background: `url(${PanelBg}), ${theme.palette.primary.light} center center/cover`,
          height: "100vh",
          width: "100%",
        }}
      >
        <Header />

        {!isOpen ? (
          <Grid
            container
            sx={{
              height: "80vh",
              pl: 2,
            }}
          >
            <Grid item lg={12} xs={12} display="flex" justifyContent="center">
              <Typography
                component="h1"
                sx={{
                  fontSize: "40px",
                  fontWeight: 600,
                  color: theme.palette.secondary.light,
                  textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                }}
              >
                ATENÇÃO! PAINEL FECHADO
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            sx={{
              height: "80vh",
              pl: 2,
            }}
          >
            <Grid item lg={5} xs={12} pt={4}>
              <Grid container>
                <Grid
                  item
                  lg={12}
                  xs={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "40px",
                      fontWeight: 600,
                      color: theme.palette.secondary.light,
                      textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      textAlign: "center",
                    }}
                  >
                    ATENÇÃO!
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={12}
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  mt={1}
                >
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: "36px",
                      fontWeight: 600,
                      color: theme.palette.background.default,
                      textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      textAlign: "center",
                    }}
                  >
                    AGUARDE VOCÊ SER CHAMADO
                  </Typography>
                </Grid>
              </Grid>
              {
                servicePasswords && servicePasswords.length > 0  && <>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    mt={1}
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "30px",
                        fontWeight: 500,
                        color: theme.palette.background.default,
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      Senha:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "36px",
                        fontWeight: 600,
                        color: theme.palette.background.default,
                        textTransform: "uppercase",
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                        textAlign: "center",
                      }}
                    >
                      {servicePasswords[0].senha}
                    </Typography>
                  </Grid>

                  {/* guiche */}
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    mt={1}
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "30px",
                        fontWeight: 500,
                        color: theme.palette.background.default,
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                      }}
                    >
                      Guichê:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography
                      component="h1"
                      sx={{
                        fontSize: "36px",
                        fontWeight: 600,
                        color: theme.palette.background.default,
                        textTransform: "uppercase",
                        textShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                        textAlign: "center",
                      }}
                    >
                      {servicePasswords[0]?.guiche?.nome ? servicePasswords[0]?.guiche?.nome : "-" }
                    </Typography>
                  </Grid>
                </>
              }
            </Grid>
            <Grid item lg={7} xs={12} display="flex" alignItems="center">
              <Grid
                container
                sx={{
                  backgroundColor: theme.palette.background.default,
                  overflowY: "scroll",
                  maxHeight: "80vh",
                  height: "100%",
                }}
              >
                <Grid item lg={12}>
                  <Grid container maxHeight="100%">
                    <Grid
                      item
                      lg={10}
                      xl={10}
                      sx={{
                        backgroundColor: theme.palette.primary.dark,
                        p: 2,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        position: "sticky",
                        top: 0,
                      }}
                    >
                      <Typography
                        component="h1"
                        sx={{
                          fontSize: "28px",
                          color: theme.palette.background.default,
                          textTransform: "uppercase",
                        }}
                      >
                        SENHA
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={2}
                      xl={2}
                      sx={{
                        backgroundColor: theme.palette.primary.dark,
                        p: 2,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        position: "sticky",
                        top: 0,
                      }}
                    >
                      <Typography
                        component="h1"
                        sx={{
                          fontSize: "28px",
                          color: theme.palette.background.default,
                          textTransform: "uppercase",
                        }}
                      >
                        GUICHÊ
                      </Typography>
                    </Grid>
                    {servicePasswords.length === 0 ? (
                      <Grid
                        item
                        lg={12}
                        sx={{
                          p: 2,
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Typography
                          component="h1"
                          sx={{
                            fontSize: "28px",
                            fontWeight: 600,
                            color: theme.palette.primary.dark,
                            textTransform: "uppercase",
                          }}
                        >
                          Nenhuma senha encontrada
                        </Typography>
                      </Grid>
                    ) : (
                      servicePasswords.map((item) => (
                        <Fragment key={item.idsenhaatendimento}>
                          <Grid
                            item
                            lg={10}
                            md={10}
                            sm={10}
                            xs={10}
                            sx={{
                              p: 2,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                              textAlign: "center",
                            }}
                          >
                            <Typography
                              component="h1"
                              sx={{
                                fontSize: "4.8rem",
                                fontWeight: 800,
                                color: theme.palette.primary.dark,
                                textTransform: "uppercase",
                                textAlign: "center",
                              }}
                            >
                              {item.senha}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={2}
                            sx={{
                              p: 2,
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <Typography
                              component="h1"
                              sx={{
                                fontSize: "4.8rem",
                                fontWeight: 800,
                                color: theme.palette.primary.dark,
                                textTransform: "uppercase",
                                textAlign: "center",
                              }}
                            >
                              {item.guiche ? item.guiche.nome : "Aguardando"}
                            </Typography>
                          </Grid>
                        </Fragment>
                      ))
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Footer />
      </Box>
    </>
  );
}

export default NewPanel;
